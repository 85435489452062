.container {
  top: 49px;
  display: grid;
  grid-template-rows: 0px min-content minmax(min-content, auto) 40px;
  grid-template-columns: 226px minmax(50px, 1fr);
  grid-template-areas:
    "left header header"
    "left templates templates"
    "left content content"
    "left footer footer";
  row-gap: 30px;
  height: 100%;
  position: absolute;
  width: 100%;
  max-height: calc(100vh - 49px);
}

.containerWithBanner.container {
  top: 91px;
  grid-template-rows: 0px 232px auto 40px;
  grid-template-areas:
    "left header header"
    "left templates templates"
    "left content content"
    "left footer footer";
  row-gap: 30px;
  max-height: calc(100vh - 91px);
}

.containerMonday.container {
  top: 49px;
  display: grid;
  grid-template-rows: 0px 45px min-content minmax(min-content, auto) 40px;
  grid-template-columns: 226px minmax(50px, 1fr);
  grid-template-areas:
    "left header header"
    "left search-templates search-templates"
    "left templates templates"
    "left content content"
    "left footer footer";
  row-gap: 30px;
  max-height: calc(100vh - 49px);
  position: absolute;
  width: 100%;
}

.containerWithBanner.containerMonday.container {
  top: 91px;
  display: grid;
  grid-template-rows: 0px 45px min-content minmax(min-content, auto) 40px;
  grid-template-columns: 226px minmax(50px, 1fr);
  grid-template-areas:
    "left header header"
    "left search-templates search-templates"
    "left templates templates"
    "left content content"
    "left footer footer";
  row-gap: 30px;
  max-height: calc(100vh - 91px);
  position: absolute;
  width: 100%;
}

.left {
  position: fixed;
  grid-area: left;
  display: flex;
  background-color: #f4f7fa;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 226px;
  overflow-y: visible;
  padding: 20px;
  z-index: 1;
  height: calc(100vh - 49px);
}

.container.containerMonday.containerWithBanner > .left {
  height: calc(100vh - 91px);
}

.container.containerMonday > .left {
  height: calc(100vh - 49px);
}

.container.containerWithBanner > .left {
  height: calc(100vh - 91px);
}

.leftWithBanner {
  top: 93px;
}

.header {
  grid-area: header;
  margin-bottom: 30px;
}

.banner {
  grid-area: banner;
  background: url(/images/new_banner.png) no-repeat center right/contain,
    linear-gradient(transparent 0%, #1973ff1a 100%) no-repeat bottom center/auto 43px,
    radial-gradient(rgba(212, 214, 255, 1) 1px, #ffffff 1px) repeat center center/15px 15px;
  padding-left: 60px;
  display: flex;
  align-items: center;
}

.banner span {
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--dark);
}

.searchTemplates {
  grid-area: search-templates;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.templates {
  grid-area: templates;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  height: fit-content;
}

.contentArea {
  grid-area: content;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  margin: 0px 37px;
  gap: 30px;
  height: fit-content;
}

.contentBackground {
  grid-area: 4 / 0 / 4 / 4;
  background-color: rgba(239, 241, 245, 0.5);
  border-top: 0.5px solid #cbcfd2;
}

.listContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  grid-gap: 25px;
}

.footer {
  grid-area: footer;
  display: flex;
  gap: 30px;
  align-items: center;
  font-size: 14px;
  padding: 0 20px;
  /* transform: translate(0, -30px); */
  margin-top: -30px;
  background: white;
}

.footer img {
  width: 100px;
}

.logo {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.footer a {
  text-decoration: underline;
  color: #797e93;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.titleLine {
  color: #11355e;
  font-family: "Sora";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.213px;
}

.templateContainer {
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: stretch;
  justify-content: center;
}

.noBoards {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.noBoards > img {
  width: 114px;
  height: 96px;
}

.noBoards > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #113357;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.129px;
  white-space: pre-line;
}

.contactYourAdmin {
  width: 517px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px;
  position: relative;
}

.skeletonCard {
  max-width: 20vw;
  min-height: 160px;
  border: 1px solid #e6e9ef;
  box-shadow: 0px 1px 8px 0px #0b264226;
  transition: all 100ms;
  margin-right: 25px;
  margin-bottom: 25px;
}

.skeletonCardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.boardsGrid {
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  grid-gap: 20px;
}
